import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-2 rounded-md bg-hub-background-3 p-2 flex"
}
const _hoisted_2 = ["href"]

import { useMatrixFiles } from '@/composables/useMatrixFiles';
	import { SMI } from '@/dev/StatusMessage';
	import { LOGGER } from '@/foundation/Logger';
	import { TFileMessageEventContent } from '@/model/events/TMessageEvent';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'MessageFile',
  props: {
    message: {}
  },
  setup(__props: any) {

	const { formUrlfromMxc } = useMatrixFiles();

	const logger = LOGGER;

	const props = __props;

	const url = getUrl();
	if (!url) {
		logger.error(SMI.ROOM_TIMELINEWINDOW, 'Url is missing in file message', props.message);
	}

	function getUrl() {
		if (!props.message.url) return undefined;
		return formUrlfromMxc(props.message.url);
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_unref(url))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Icon, {
          type: "paperclip",
          class: "mr-2"
        }),
        _createElementVNode("a", {
          class: "text-blue",
          target: "_blank",
          href: _unref(url)
        }, _toDisplayString(_ctx.message.filename), 9, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}
}

})